/* eslint no-extend-native: 0 */
/* eslint import/no-extraneous-dependencies: 0 */
import 'polyfill-array-includes';
import isnan from 'core-js/library/fn/number/is-nan';
import values from 'core-js/library/fn/object/values';

require('intersection-observer');

Number.isNaN = isnan;
Object.values = values;
